const config = {
  local: {
    endpoint: {
      releases:
        'https://xkorzanniuere.complex.com/https://api2.solecollector.com/sneakers/releases/SNEAKER_ID',
      spotlight: 'https://xkorzanniuere.complex.com/https://api2.solecollector.com/home?target=web',
      markets: 'https://xkorzanniuere.complex.com/https://api2.solecollector.com/markets',
    },
  },
  production: {
    endpoint: {
      releases: 'https://api2.solecollector.com/sneakers/releases/SNEAKER_ID',
      spotlight: 'https://api2.solecollector.com/home?target=web',
      markets: 'https://api2.solecollector.com/markets',
    },
  },
};

// Match client side host and get which environment
const host = window && window.location.host;
const env = host === 'embed.solecollector.com' ? 'production' : 'local';
export const defaultSneakerImageCloudinaryId = 'SC_SNEAKER_PLACEHOLDER_AJ3_GREY_01_lkdqxj';
export default config[env];
