import React, {useState, useEffect, useCallback, useRef, Suspense} from 'react';
import WidgetConfig, {allowedWidgetLocations} from 'config/widget';
import {defaultSneakerImageCloudinaryId} from 'config';
import API from './api';

export const numberFormat = (value) =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  }).format(value);

export const parentIframeResize = (dropdownRows = 1) => {
  const mininumHeight = 170;
  const body = document.body;
  const frameId = window.name || null;
  let height = Math.max(body.offsetHeight + 30, mininumHeight);

  if (dropdownRows > 1 && height <= mininumHeight) {
    height = height + dropdownRows * 31;
  }

  window.top.postMessage({__SCMPID: frameId, __SCMPHEIGHT: height}, '*');
};

export const sendGAEvent = (gaEvent) => {
  const message = {
    event: 'custom.postMessage.scwidget',
    gaEvent: gaEvent,
  };
  window.parent.postMessage(JSON.stringify(message), '*');
};

export const useWindowWidth = () => {
  const getSize = () => {
    return window.innerWidth;
  };

  const [windowWidth, setWindowWidth] = useState(getSize);

  useEffect(() => {
    let timeoutId = null;
    const handleResize = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => setWindowWidth(getSize()), 500);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowWidth;
};

export const appendParamsToURL = (url, params = {}) => {
  try {
    let tmp = new URL(url);

    for (let key in params) {
      if (key === '/pubref:') {
        const modURL = tmp.href.replace('/destination:', `${key}${params[key]}/destination:`);
        tmp = new URL(modURL);
      } else {
        tmp.searchParams.append(key, params[key]);
      }
    }

    return tmp.toJSON();
  } catch (err) {
    return url;
  }
};

export const getPurchaseURLParamsByMarket = (market, customParams) => {
  try {
    const config = WidgetConfig[customParams.site].URLParamsMarket[market];
    const hash = window.location.hash;
    const params = new URLSearchParams(hash.substring(hash.lastIndexOf('?') + 1));
    const utmCampaign = params.get('utm_campaign');
    const utmSource = params.get('utm_source');
    const utmMedium = params.get('utm_medium');
    const articleId = params.get('articleId');
    const widgetLocation = params.get('location') || 'webCX';

    if (!allowedWidgetLocations.includes(widgetLocation)) {
      throw new Error('Widget location not allowed.');
    }

    if (
      [
        'adidas',
        'champs',
        'converse',
        'eastbay',
        'foot-locker',
        'goat',
        'kickscrew',
        'puma',
        'reebok',
        'stadium-goods',
        'stockx',
        'flight-club',
      ].includes(market)
    ) {
      const configCopyA = {...config};
      const paramKey = Object.keys(configCopyA)[0];
      const styleCode = (customParams.styleCode || '').replace(/-/g, '');
      configCopyA[paramKey] = `${widgetLocation}-${customParams.releaseId}-${styleCode}`;

      if (articleId) {
        configCopyA[paramKey] += `-${articleId}`;
      }

      return configCopyA;
    }

    if (utmCampaign || utmSource || utmMedium) {
      if (['footaction'].includes(market)) {
        return {
          ...config,
          ...(utmCampaign && {subId2: utmCampaign.toLowerCase()}),
          ...(utmSource && {subId3: utmSource.toLowerCase()}),
          ...(utmMedium && {sharedid: utmMedium.toLowerCase()}),
        };
      }
    }

    return config;
  } catch (err) {
    return {};
  }
};

const isValidURL = (string) => {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
};

export const getCloudinaryImageUrl = (cloudinaryId, width = 230, height = 130) => {
  const assetId =
    typeof cloudinaryId === 'string' && cloudinaryId.length > 0
      ? cloudinaryId
      : defaultSneakerImageCloudinaryId;

  return isValidURL(assetId)
    ? assetId
    : `https://images.solecollector.com/complex/image/upload/w_${width},h_${height},q_auto,f_auto,dpr_2.0/${assetId}`;
};

export const useEventCallback = (fn, dependencies) => {
  const ref = useRef(null);

  useEffect(() => {
    ref.current = fn;
    // eslint-disable-next-line
  }, [fn, ...dependencies]);

  return useCallback(
    (...args) => {
      const fn = ref.current;
      return fn(...args);
    },
    [ref]
  );
};

export const getSneakerStyleCode = async (sneakerId) => {
  try {
    const {
      data: {style_code},
    } = await API.releases(sneakerId).get();
    return style_code;
  } catch (err) {
    return null;
  }
};

export const suspenseWrap = (XView) => () => (
  <Suspense fallback={<div>...</div>}>
    <XView />
  </Suspense>
);
