import React, {lazy} from 'react';
import 'styles/global.css';
import {HashRouter as Router, Switch, Route} from 'react-router-dom';
import {suspenseWrap} from 'helpers';
const SneakerOffersList = suspenseWrap(lazy(() => import('./SneakerOffersList')));
const SneakerOffersListX = suspenseWrap(lazy(() => import('./SneakerOffersListX')));
const BuyerGuideCarousel = suspenseWrap(lazy(() => import('./BuyerGuideCarousel')));
const GenericCarousel = suspenseWrap(lazy(() => import('./GenericCarousel')));

const RouterIndex = () => (
  <Router>
    <Switch>
      <Route path="/sneaker-offers/:id/:site?" component={SneakerOffersList}></Route>
      <Route path="/generic-carousel/:site?" component={GenericCarousel}></Route>
      <Route path="/bguide-carousel/:site?" component={BuyerGuideCarousel}></Route>
      <Route path="/sneaker-offers-x/:id/:site?" component={SneakerOffersListX}></Route>
    </Switch>
  </Router>
);

export default RouterIndex;
