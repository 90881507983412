import config from 'config';

class API {
  constructor() {
    this._endpoint = null;
    this._headers = {
      Accept: 'application/json',
    };
  }

  static fetchData = async (url) => {
    let data = null;
    try {
      data = await fetch(url, {
        headers: this._headers,
      }).then((r) => r.json());
    } catch (err) {
      console.log(err);
    }
    return data;
  };

  static releases = (sneakerId) => {
    this._endpoint = config.endpoint.releases.replace('SNEAKER_ID', sneakerId);
    return this;
  };

  static offers = () => {
    this._endpoint = `${this._endpoint}/offers?target=web`;
    return this;
  };

  static spotlight = () => {
    this._endpoint = config.endpoint.spotlight;
    this._headers = {};
    return this;
  };

  static markets = () => {
    this._endpoint = config.endpoint.markets;
    return this;
  };

  static get = () => {
    return API.fetchData(this._endpoint);
  };
}

export default API;
