export default {
  complex: {
    URLParamsMarket: {
      stockx: {
        subId1: '',
      },
      adidas: {
        subId1: '',
      },
      goat: {
        subId1: '',
      },
      'flight-club': {
        subId1: '',
      },
      kickscrew: {
        subId1: '',
      },
      converse: {
        sid: '',
      },
      puma: {
        sid: '',
      },
      'stadium-goods': {
        '/pubref:': '',
      },
      reebok: {
        afftrack: '',
      },
      'foot-locker': {
        subId1: '',
      },
      footaction: {
        subId1: 'cx-widget',
      },
      champs: {
        subId1: '',
      },
      eastbay: {
        subId1: '',
      },
    },
  },
  solecollector: {
    URLParamsMarket: {
      stockx: {
        subId1: '',
      },
      adidas: {
        subId1: '',
      },
      goat: {
        subId1: '',
      },
      'flight-club': {
        subId1: '',
      },
      kickscrew: {
        subId1: '',
      },
      converse: {
        sid: '',
      },
      puma: {
        sid: '',
      },
      'stadium-goods': {
        '/pubref:': '',
      },
      reebok: {
        afftrack: '',
      },
      'foot-locker': {
        subId1: '',
      },
      footaction: {
        subId1: 'sc-widget',
      },
      champs: {
        subId1: '',
      },
      eastbay: {
        subId1: '',
      },
    },
  },
};

export const allowedWidgetLocations = ['webCX', 'webSN', 'webSC', 'webSD'];
